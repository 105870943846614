import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue';
import NotFoundPage from '../pages/NotFoundPage';
import LoginPage from '../pages/LoginPage.vue';
import ErrorPage from '../pages/ErrorPage.vue';
import UserInfoPage from '../pages/UserInfoPage.vue';
import MetadataPage from '../pages/MetadataPage.vue';
import ACHDetailsPage from '../pages/ACHDetailsPage.vue';
import PaymentPage from '../pages/PaymentPage.vue';
import PaymentsPage from '../pages/PaymentsPage.vue';
import PaymentStatusPage from '../pages/PaymentStatusPage.vue';
import SearchPage from '../pages/SearchPage.vue';
import SetPrices from '../pages/SetPrices.vue';
import VolunteerSearchPage from '../pages/VolunteerSearchPage.vue';
import VolunteerPermissionsPage from '../pages/VolunteerPermissionsPage.vue';
import VolunteerManagementPage from '../pages/VolunteerManagementPage.vue';
import VolunteerEditPage from '../pages/VolunteerEditPage';
import SetTerms from '../pages/SetTerms.vue'
import PortalPage from '../pages/PortalPage.vue'
import BatchesReportPage from '../pages/BatchesReportPage.vue'
import BatchPaymentsReportPage from '../pages/BatchPaymentsReportPage.vue'
import CollectionsReportPage from '../pages/CollectionsReportPage.vue'
import CollectionsComparisonReportPage from '../pages/CollectionsComparisonReportPage.vue'
import DonationsReportPage from '../pages/DonationsReportPage.vue'
import PendingPaymentsPage from '../pages/PendingPaymentsPage.vue'
import ProcessingPaymentsReportPage from '../pages/ProcessingPaymentsReportPage.vue'
import UnregisteredMembersReportPage from '../pages/UnregisteredMembersReportPage.vue'
import SubsidizePage from '../pages/SubsidizePage'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        props: true,
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        props: true,
    },
    {
        path: '/userinfo',
        name: 'UserInfo',
        component: UserInfoPage
    },
    {
        path: '/metadata',
        name: 'Metadata',
        component: MetadataPage
    },
    {
        path: '/payment',
        name: 'Payment',
        component: PaymentPage
    },
    {
        path: '/achdetails',
        name: 'ACHDetails',
        component: ACHDetailsPage
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchPage
    },
    {
        path: '/paymentstatus/:paymentId',
        name: 'PaymentStatus',
        component: PaymentStatusPage,
        props: true
    },
    {
        path: '/payments',
        name: 'Payments',
        component: PaymentsPage,

    },
    {
        path: '/setprices',
        name: 'SetPrices',
        component: SetPrices,

    },
    {
        path: '/volunteer/search',
        name: 'VolunteerSearch',
        component: VolunteerSearchPage,
    },
    {
        path: '/volunteer/permissions',
        name: 'VolunteerPermissions',
        component: VolunteerPermissionsPage,

    },
    {
        path: '/volunteer/management',
        name: 'VolunteerManagement',
        component: VolunteerManagementPage,

    },
    {
        path: '/volunteer/edit',
        name: 'VolunteerEdit',
        component: VolunteerEditPage,
    },
    {    
        path: '/setterms',
        name: 'SetTerms',
        component: SetTerms,

    },
    {
        path: '/batchpayments/:batchId',
        name: 'BatchPaymentsReport',
        component: BatchPaymentsReportPage,
        props: true,
    },
    {
        path: '/batchesreport',
        name: 'BatchesReport',
        component: BatchesReportPage,
    },
    {
        path: '/collectionsreport',
        name: 'CollectionsReport',
        component: CollectionsReportPage,
    },
    {
        path: '/collectionscomparisonreport',
        name: 'CollectionsComparisonReport',
        component: CollectionsComparisonReportPage,
    },
    {
        path: '/donationsreport',
        name: 'DonationsReport',
        component: DonationsReportPage,
    },
    {
        path: '/pendingpayments',
        name: 'PendingPayments',
        component: PendingPaymentsPage,
    },
    {
        path: '/processingpaymentsreport',
        name: 'ProcessingPaymentsReport',
        component: ProcessingPaymentsReportPage,
    },
    {
        path: '/unregisteredmembersreport',
        name: 'UnregisteredMembersReport',
        component: UnregisteredMembersReportPage,
    },
    {
        path: '/portal',
        name: 'Portal',
        component: PortalPage,
    },
    {
        path: '/subsidize',
        name: 'Subsidize',
        component: SubsidizePage
    },
    {
        path: '/:catchApp(.*)',
        name: 'NotFound',
        component: NotFoundPage,
        meta: {
          noauth: true,
        }
    },
    {
        path: '/notfound',
        name: 'NotFoundAll',
        component: NotFoundPage,
        meta: {
          noauth: true,
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router