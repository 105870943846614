<template>
<div>
    <NavigationBar />
    <div class="container" style="padding-top: 20px;">
        <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="error">
            <i class="bi-exclamation-octagon-fill"></i>
            <strong class="mx-2">Error!</strong> {{ error }}
            <br />
        </div>
        <h2>Collections Comparison Report</h2>
        <div class="searchInputs">
            <div class="searchInputRow">
                <div class="searchInputItem" v-if="regions">
                    <label for="region" class="col-form-label"><b>Region</b></label>
                    <select class="form-select" id="region" v-model="region">
                        <option value="-1" selected>Please select</option>
                        <option v-for="region in regions" :value="region.id" :key="region.id">{{region.name}}</option>
                    </select>
                </div>
                <div class="searchInputItem">
                    <label for="jamatkhana" class="col-form-label"><b>Jamatkhana</b> </label>
                    <select class="form-select" id="jamatkhana" v-model="jamatkhana" :disabled="region === '-1'">
                        <option v-if="regions" value="-1" selected>All Jamatkhanas</option>
                        <option v-for="jk in filterJamatkhanas(region)" :value="jk.id" :key="jk.id">{{jk.name}}</option>
                    </select>
                </div>
            </div>
            <div class="searchInputRow">
                <div class="searchInputItem">
                    <button class="col-auto btn btn-success" :disabled="disableRunReport" @click="runReport">Run Report</button>
                </div>
            </div>
        </div>
        <div v-if="loadingCollectionReport">
            <LoadingSpinner />
        </div>
        <div class="chart" v-if="collectionComparisonReport && !loadingCollectionReport && chartLabels.length > 0 && chartDatasets.length > 0 && customChartOptions">
              <div>
                <BarChart :labels="chartLabels" :datasets="chartDatasets" :chart-options="customChartOptions" />
            </div>
        </div>    
        <div v-if="collectionComparisonReport && !loadingCollectionReport">
            <table class="table">
                <tbody>
                    <tr>
                        <td><b>Jamatkhana</b></td>
                        <td><b>{{collectionComparisonReport.previousYear}}</b></td>
                        <td><b>{{collectionComparisonReport.currentYear}}</b></td>
                        <td><b>Percent</b></td>
                    </tr>
                    <tr v-for="row in collectionComparisonReport.result" :key="row.jamatkhanaId">
                        <td>{{getJamatkhanaName(row.jamatkhanaId)}}</td>
                        <td>{{row[collectionComparisonReport.previousYear]}}</td>
                        <td>{{row[collectionComparisonReport.currentYear]}}</td>
                        <td>{{calculatePercentage(row[collectionComparisonReport.previousYear], row[collectionComparisonReport.currentYear])}}</td>
                    </tr>
                </tbody>
            </table>
        </div>    
    </div>
</div>

</template>

<script>
import axios from 'axios';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import BarChart from "../components/BarChart.vue";
import { getVolunteerJks } from '../utils/MethodUtils.js';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: "CollectionsComparisonReportPage",
    components: {
        NavigationBar,
        LoadingSpinner,
        BarChart
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const regions = ref(store.getters.getMetadata.regions);
        const region = ref("-1");
        const jamatkhanas = ref(store.getters.getMetadata.jamatkhanas);
        const jamatkhana = ref("-1");
        const error = ref(null);
        const loadingCollectionReport = ref(false);
        const collectionComparisonReport = ref();
        const chartLabels = ref([]);
        const chartDatasets = ref([]);
        const customChartOptions = ref()

        const filterJamatkhanas = (id) => {
            let returnJks = []
            for (let jk of jamatkhanas.value) {
                if (jk.regionId === id) {
                    returnJks.push(jk)
                }
            }
            return returnJks
        }

        const disableRunReport = computed(() => {
            return !(
                region.value != "-1"
            );
        })

        const getJamatkhanaName = (id) => {
            const methods = store.getters.getMetadata.jamatkhanas;
            const filteredMethod = methods.filter(method => method.id === id);
            if (filteredMethod.length === 1) {
                return filteredMethod[0].name
            } else {
                return id;
            }
        }

        const calculatePercentage = (num1, num2) => {
            const number1 = parseFloat(num1);
            const number2 = parseFloat(num2);
            if (number1 === 0) {
                if (number2 === 0) {
                    return "0.00%"
                }
                return "100.00%";
            }
            const percentage = (number2 / number1) * 100;
            return `${percentage.toFixed(2)}%`;
        }

        const mapDataToChartData = (data) => {
            const labels = []
            const datasets = [
                {
                    label: data.previousYear,
                    data: [],
                    backgroundColor: "#3498db"
                },
                {
                    label:data.currentYear,
                    data: [],
                    backgroundColor: "#e74c3c",
                }
            ]
            data.result.forEach(obj => {
                datasets[0].data.push(obj[data.previousYear])
                datasets[1].data.push(obj[data.currentYear])
                labels.push(getJamatkhanaName(obj.jamatkhanaId))
            })
            console.log(labels)
            console.log(datasets)
            chartLabels.value = labels;
            chartDatasets.value = datasets

            customChartOptions.value = {
                responsive: true,
                plugins: {
                    legend: {
                        position: "bottom",
                        align: "center",
                    },
                    title: {
                        display: true,
                        text: "FEP Performance Bar Chart Year-Over-Year",
                    },
                    tooltip: {
                        enabled: false
                    },
                    datalabels: {
                        display: true,
                        color: 'black',
                        anchor: 'end',
                        align: 'start',
                        formatter: (value) => value,
                        offset: -20,
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            maxRotation: 90,
                            minRotation: 45,
                            autoSkip: false,
                        },
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                    y: {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                }
            }
        }


        const runReport = async () => {
            error.value = null;
            loadingCollectionReport.value = true;

            let params = {}
            if (jamatkhana.value != "-1") {
                params.jamatkhanaId = jamatkhana.value;
            } else {
                params.regionId = region.value;
            }
            try {
                const response = await axios.get('/api/v1/admin/reports/collectioncomparison', { params });
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    collectionComparisonReport.value = data;
                    mapDataToChartData(data);
                    console.log(data)
                } else {
                    console.log(statusMessage);
                    error.value=statusMessage;
                }
            } catch (err) {
                console.log(err);
                error.value = err
            }
            loadingCollectionReport.value = false;
        }

        return {
            chartLabels,
            chartDatasets,
            customChartOptions,
            getJamatkhanaName,
            calculatePercentage,
            loadingCollectionReport,
            disableRunReport,
            filterJamatkhanas,
            collectionComparisonReport,
            runReport,
            regions,
            region,
            jamatkhanas,
            jamatkhana,
            error,
        }

    },
    async created() {
        let volunteer = this.$store.getters.getVolunteerInfo;
        this.regions = volunteer.regions;
        if(!this.regions) // we only have jks
        {
            let volJkInfo = getVolunteerJks();
            this.jamatkhanas = volJkInfo.jamatkhanas;
            this.jamatkhana = volJkInfo.selectedJamatkhana;
            this.region = this.jamatkhanas[0].regionId;        
        }
        if (!volunteer.resources.some(permission => permission.id === 3)) {
            this.$router.push("/search");
        }
    }
}
</script>

<style scoped>
.chart {
    padding-bottom: 40px;
}
.searchInputs {
    padding-bottom: 20px;
}
.searchInputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}
.searchInputRow:nth-last-child(2) {
    padding-bottom: 10px;
}
.searchInputItem {
    padding-right: 20px;
    min-width: 200px;
}
.reportOptions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.link {
    color: #0d6efd;
    text-decoration: underline;
}
.link:hover {
    cursor: pointer;
}
</style>
