<template>
    <div>
        <NavigationBar/>
        <div v-if="loading">
          <LoadingSpinner />
        </div>
        <div v-else class="container">
            <div v-if="paymentNotFound">
                <center>
                <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show">
                    <i class="bi-exclamation-octagon-fill"></i>
                    <strong class="mx-2">Error!</strong> {{ error }}
                    <br />
                    </div>
                <a class="btn btn-primary" href="/" >Back To Homepage</a>
                </center>
            </div>
            <div class="paymentStatusBody" v-else>
                <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="emailSuccess === false">
                    <i class="bi-exclamation-octagon-fill"></i>
                    <strong class="mx-2">Error!</strong> {{ emailMessage }} {{isEmailButtonClicked ? `` : `However, your payment was still processed.`}}
                    <br />
                </div>
                <div class="alert alert-success alert-dismissible d-flex align-items-center fade show" v-if="emailSuccess">
                    <i class="bi-check-circle-fill"></i>
                    <strong class="mx-2">Success!</strong> {{ emailMessage }}
                </div>
                <h2>Payment Status</h2>
                <div class="paymentMessageContainer">
                    <div v-if="paymentMethod.id === 3" class="paymentDetail" v-html="achInstructions"/>
                    <div v-else-if="mailingInstructions" class="paymentDetail" v-html="mailingInstructions"/>
                    <div v-else>Thank you for completing the burial registration form. Please go to the burial registration desk in your Jamatkhana for instructions on sending your payment.</div>
                </div>
                <div class="paymentInfo">
                    <b>Payment ID:</b> {{paymentId}}<br>
                    <b>Coverage Year:</b> {{coverageYear}}<br>
                    <div v-if="familyLocationName"><b>Coverage Location:</b> {{familyLocationName}}<br></div>
                    <b>Payment Method:</b> {{paymentMethod.name}}<br>
                    <div v-if="paymentMethod.id === 1 && paymentStatus=== 'PAID'"><b>Check Number:</b> {{paymentInfo.checkNumber}}<br></div>
                    <div v-if="paymentMethod.id === 2 && paymentStatus=== 'PAID'"><b>Money Order Number:</b> {{paymentInfo.moneyOrderNumber}}<br></div>
                    <b>Payment Status:</b> {{paymentStatus}}<br>
                    <b>Initiated By:</b> {{paymentInitiatedBy}}<br>
                    <b>Initiated By Member ID:</b> {{paymentInitiatedById}}<br>
                    <b>Initiated On:</b> {{paymentInitiatedAt}}<br>
                    <div v-if="paymentStatus === 'PAID'">
                        <div v-if="isInVolunteerFlow">
                            <b>Received By:</b> {{paymentReceivedBy}}<br>
                        </div>
                        <b>Received On:</b> {{paymentReceivedAt}}<br>
                        <div v-if="paymentMethod.id !== 3">
                            <b>Received As:</b> {{paymentReceivedAs}}<br>
                        </div>
                    </div> <br/>
                    <div class="volunteerInputItem" v-if="isInVolunteerFlow && paymentStatus === 'PAID' && hasPreviousAdjustments && this.volunteerInfo.resources.some(permission => permission.id === 7)">
                        <button class="col-auto btn btn-outline-primary" @click="adjustMemberData" data-bs-toggle="modal" data-bs-target="#adjustmentModal">View Adjustment</button>
                    </div>
                </div>
                <h4 class="pt-3 text-center">Covered Members</h4>
                <div class="table-responsive household-Member-Table pt-2">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="familyMember in members || []" :key="familyMember.id"  @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                            <td> {{ familyMember.firstName }} {{ familyMember.lastName }}</td>
                            <td>{{ displayInUSD(familyMember.rate) }}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr  v-if="donation">
                            <td><b>Members Total:</b></td>
                            <td>{{displayInUSD(membersTotal)}}</td>
                        </tr>
                        <tr class="noBorder" v-if="donation">
                            <td><b>Donation:</b></td>
                            <td>{{displayInUSD(donation)}}</td>
                        </tr>
                        <tr>
                            <td><b>Total:</b></td>
                            <td><b>{{displayInUSD(total)}}</b></td>                    
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="pageOptions">
                    <button 
                        v-if="isInVolunteerFlow && paymentStatus.toLowerCase() == 'paid' && this.volunteerInfo.resources.some(permission => permission.id === 11)" 
                        class="btn btn-outline-danger buttonOption" 
                        data-bs-toggle="modal" 
                        data-bs-target="#confirmReturnModal">
                        Mark As Returned
                    </button>
                    <button v-if="paymentStatus.toLowerCase() == 'pending'" class="btn btn-outline-danger buttonOption" data-bs-toggle="modal" data-bs-target="#confirmCancelModal">Cancel Payment</button>
                    <button class="btn btn-primary buttonOption" @click="printReceipt">Print</button>
                </div>
                <div class="pageOptions">
                    <button v-if="paymentStatus.toLowerCase() != 'returned'" class="btn btn-primary buttonOption" :disabled="emailButtonDisabled" @click="sendStatusEmail">Resend Confirmation Email</button>
                </div>
                <div class="pageOptions">
                    <button v-if="termsAndConditions"
                        class="btn btn-link btn-md" 
                        style="padding:0; border:0; vertical-align:top;"
                        data-bs-toggle="modal" 
                        data-bs-target="#termsModal">Terms and Conditions
                    </button>
                </div>
                <hr>
                <div v-if="isInVolunteerFlow && paymentStatus.toLowerCase() == 'pending' && paymentMethod.id !== 3" class="volunteerPaymentContainer">
                    <div class="volunteerInputItem">
                        <label for="checkNumber" ><b>{{ paymentMethod.name }} Number:</b></label>
                        <input class="form-control" type="text" id="checkNumber" v-model="checkNumber">
                    </div>
                    <div class="volunteerInputItem">
                        <input class="form-check-input" type="radio" name="receiptMethod" id="IN_PERSON" value="In Person" v-model="receiptMethod">
                        <label class="form-check-label radioLabel" for="IN_PERSON">In Person</label>

                        <input  class="form-check-input" type="radio" name="receiptMethod" id="MAIL" value="Mail" v-model="receiptMethod">
                        <label class="form-check-label radioLabel" for="MAIL">Mail</label>
                    </div>
                    <div class="volunteerInputItem">
                        <label for="jamatkhana">Jamatkhana payment was received at:</label>
                        <select class="form-select dropdown-input" id="jamatkhana" v-model="selectedJamatkhana">
                            <option value="N/A">Select an option</option>
                            <option v-for="jk in jamatkhanas" :key="jk.id" :value="jk.id">{{jk.name}}</option>
                        </select>
                    </div>
                    <div class="volunteerInputItem">
                        <button class="col-auto btn btn-success" :disabled="disableMarkAsPaid" @click="markAsPaid">Mark As Paid</button>
                    </div>
                    <div class="volunteerInputItem" v-if="this.volunteerInfo.resources.some(permission => permission.id === 7)">
                        <button class="col-auto btn btn-outline-primary" @click="adjustMemberData" data-bs-toggle="modal" data-bs-target="#adjustmentModal">{{hasPreviousAdjustments ? "Edit Adjustment" : "Add Adjustment"}}</button>
                    </div>
                </div>
                <div class="modal modal-lg" id="adjustmentModal" tabindex="-1" aria-labelledby="adjustmentModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="adjustmentModalLabel">Price Adjustment</h5>
                                <button type="button" class="btn-close" @click="closeModal('adjustmentModal')" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div v-if="!isAdjustmentModalReady">
                                    <LoadingSpinner />
                                </div>
                                <div v-else>
                                    <div class="alert alert-danger alert-dismissible d-flex align-items-center fade show" v-if="adjustmentModalstatus">
                                        <strong class="mx-2">Error!</strong> {{ adjustmentModalstatus }}<br />
                                    </div>
                                    <div class="table-responsive household-Member-Table pt-2">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Original Amount</th>
                                                <th>Adjusted Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="familyMember in members || []" :key="familyMember.id"  @click.right.prevent @keydown="keydown" @copy.prevent @paste.prevent>
                                                <td> {{ familyMember.firstName }} {{ familyMember.lastName }}</td>
                                                <td>{{ displayInUSD(familyMember.originalRate || familyMember.rate) }}</td>
                                                <td v-if="hasPreviousAdjustments && paymentStatus == 'PAID'">{{ displayInUSD(familyMember.subsidizedRate) }}</td>
                                                <td v-else><CurrencyInput v-model="familyMember.subsidizedRate" :options="{ currency: 'USD', precision: 2, valueRange: {min: 0} }"></CurrencyInput></td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr  v-if="donation">
                                                <td><b>Members Total:</b></td>
                                                <td>{{displayInUSD(getOriginalTotal())}}</td> 
                                                <td>{{displayInUSD(subsidizedMemberTotal)}}</td>
                                            </tr>
                                            <tr class="noBorder" v-if="donation">
                                                <td><b>Donation:</b></td>
                                                <td>{{displayInUSD(donation)}}</td>
                                                <td>{{displayInUSD(donation)}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Total:</b></td>
                                                <td><b>{{displayInUSD(getOriginalTotal() + donation)}}</b></td>   
                                                <td><b>{{displayInUSD(subsidizedTotal)}}</b></td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div class="volunteerInputItem">
                                        <label for="subsidyReason" ><b>Adjustment Reason (Required):</b></label>
                                        <input v-if="paymentStatus.toLowerCase() == 'pending'" class="form-control" type="text" id="subsidyReason" v-model="subsidyReason">
                                        <div v-else>{{paymentInfo.subsidyInfo.subsidyReason}}</div>
                                    </div>
                                    <div class="modalButtonRight" v-if="paymentStatus.toLowerCase() == 'pending'">
                                        <button class="btn btn-success" @click="confirmAdjustment" :disabled="disableAdjustmentSubmit">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="confirmCancelModal" tabindex="-1" aria-labelledby="confirmCancelModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="confirmCancelModalLabel">Confirm Cancelation</h5>
                                <button type="button" class="btn-close" @click="closeModal('confirmCancelModal')" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div>Are you sure you want to cancel this payment?</div>
                                <div class="modalButton">
                                    <button class="btn btn-danger" @click="confirmCancel">Yes, cancel this payment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" id="confirmReturnModal" tabindex="-1" aria-labelledby="confirmReturnModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="confirmReturnModalLabel">Confirm Return</h5>
                                <button type="button" class="btn-close" @click="closeModal('confirmReturnModal')" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div>Are you sure you want to mark this payment as returned? This cannot be undone once setting this status to returned.</div>
                                <div class="modalButton">
                                    <button class="btn btn-danger" @click="confirmReturn">Yes, Mark as Returned</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal" style="filter:drop-shadow(10px 10px 4px #666); width:100%; height:100%; padding: 20pt 20pt" id="termsModal" tabindex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" style="height:100%; width:100%; --bs-modal-width:100%; margin:0;">
                        <div class="modal-content" style="height:100%; width:100%">
                            <div class="modal-header">
                                <h5 class="modal-title" id="termsModalLabel">Terms and Conditions</h5>
                                <button type="button" class="btn-close" @click="closeModal('termsModal')" aria-label="Close"></button>
                            </div>
                            <div class="modal-body" style="height:100%; width:100%">
                                <iframe width="100%" height="100%" :src="termsAndConditions" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { ref, computed } from 'vue';
import NavigationBar from '../components/NavigationBar.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import CurrencyInput from '../components/CurrencyInput.vue';
import {
    Modal
} from 'bootstrap';
import { displayInUSD, replacePaymentId, getLocalTimestampfromUtcTimeStamp } from '../utils/MethodUtils.js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    name: "PaymentStatusPage",
    components: {
        NavigationBar,
        LoadingSpinner,
        CurrencyInput,
    },
    props:['paymentId', 'emailResponseSuccess', 'emailResponseMessage'],
    setup(props) {
        const loading = ref(true);
        const error = ref("");
        const coverageYear = ref();
        const paymentMethod = ref();
        const paymentStatus = ref();
        const paymentInitiatedAt = ref();
        const paymentInitiatedBy = ref();
        const paymentInitiatedById = ref();
        const paymentReceivedAt = ref();
        const paymentReceivedBy = ref();
        const paymentReceivedAs = ref();
        const members = ref([]);
        const donation = ref();
        const membersTotal = ref();
        const total = ref();
        const store = useStore();
        const isInVolunteerFlow = ref(store.getters.getIsInVolunteerFlow);
        const paymentNotFound = ref(false);
        const checkNumber = ref();
        const receiptMethod = ref();
        const jamatkhanas = ref();
        const selectedJamatkhana = ref();
        const achInstructions = ref("");
        const mailingInstructions = ref("");
        const termsAndConditions = ref();
        const familyLocationName = ref();
        const familyLocationId = ref();
        const emailSuccess = ref(props.emailResponseSuccess);
        const emailMessage = ref(props.emailResponseMessage);
        const isEmailButtonClicked = ref(false);
        const emailButtonDisabled = ref(false);
        const isAdjustmentModalReady = ref(false);
        const subsidyReason = ref();
        const adjustmentModalstatus = ref("");
        const paymentInfo = ref();

        const router = useRouter();

        const getPaymentStatus = async () => {
            try {
                loading.value=true
                let response = null;
                if (isInVolunteerFlow.value) {
                    response = await axios.get(`/api/v1/iiplatform/search?paymentId=${props.paymentId}`)
                } else {
                    response = await axios.get(`/api/v1/burial/payment/${props.paymentId}`);
                }
                const { success, statusMessage } = response.data;
                if (success) {
                    paymentNotFound.value = false;
                    const { data } = response.data;
                    coverageYear.value = data.planYear;
                    paymentMethod.value = data.method;
                    members.value = data.members;
                    donation.value = data.donation;
                    paymentStatus.value = data.status.name;
                    familyLocationId.value = data.familyLocationId;
                    familyLocationName.value = data.familyLocationName;
                    paymentInitiatedBy.value = data.paymentInitiatedBy.name;
                    paymentInitiatedById.value = data.paymentInitiatedBy.id;
                    paymentInitiatedAt.value = getLocalTimestampfromUtcTimeStamp(data.paymentInitiatedAt);
                    paymentInfo.value = JSON.parse(data.method.info)
                    if (paymentInfo.value && paymentInfo.value.subsidyInfo) {
                        subsidyReason.value = paymentInfo.value.subsidyInfo.subsidyReason;
                    }
                    if (paymentStatus.value === "PAID") {
                        paymentReceivedBy.value = data.paymentReceivedBy ? data.paymentReceivedBy.name : null;
                        paymentReceivedAt.value = data.paymentReceivedAt ? getLocalTimestampfromUtcTimeStamp(data.paymentReceivedAt) : null;
                        paymentReceivedAs.value = `${data.paymentReceivedAs.method} at ${data.paymentReceivedAs.jamatkhana}`
                    }
                    total.value = data.total;
                    let subtotal = 0;
                    data.members.forEach(member => subtotal += member.rate);
                    membersTotal.value = subtotal;
                    if (familyLocationId.value)
                        await getInstructionsAndTerms();
                    if(paymentMethod.value.id === 3)
                        await getACHInstructions();
                } else {
                    console.log(statusMessage);
                    error.value = statusMessage;
                    paymentNotFound.value = true;
                }
                loading.value = false;
            } catch (err) {
                console.error(err);
                error.value = err;
                paymentNotFound.value = true;
                loading.value = false;
            }
        }

        const getACHInstructions = async () => {
            try {
                const response = await axios.get(`/api/v1/admin/ach-instructions`)
                const {success, statusMessage, data} = response.data;
                achInstructions.value = data.achInstructions;
            } catch (err) {
                console.error(err);
            }
        }

        const getInstructionsAndTerms = async () => {
            try {
                const jkId = familyLocationId.value;
                const response = await axios.get(`/api/v1/admin/instructions-and-tos/${jkId}`)
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    mailingInstructions.value = replacePaymentId(props.paymentId, data.instructions);
                    termsAndConditions.value = data.termsAndConditionLink;
                } else {
                    console.log(statusMessage)
                }
            } catch (err) {
                console.error(err);
            }
        }

        const printReceipt = () => {
            window.print();
        }

        const sendStatusEmail = async () => {
            isEmailButtonClicked.value = true;
            emailButtonDisabled.value = true;
            try {
                const response = await axios.post(`/api/v1/burial/sendemail`, {
                    paymentId: props.paymentId
                })
                const { success, statusMessage, data } = response.data;
                emailButtonDisabled.value = false;
                if (success) {
                    emailSuccess.value = data.emailConfirmation.success
                    emailMessage.value = data.emailConfirmation.statusMessage
                } else {
                    emailSuccess.value = false;
                    emailMessage.value = statusMessage;
                }
            } catch (err) {
                console.log(err)
                emailButtonDisabled.value = false;
                email.Success.value = false
                emailMessage.value = "An error occurred when attempting to send email."
            }
        }

        const confirmCancel = async () => {
            try {
                const response = await axios.delete(`/api/v1/burial/payment/${props.paymentId}`);
                const { success, statusMessage } = response.data;
                if (success) {
                    closeModal("confirmCancelModal");
                    router.push("/payments");
                } else {
                    console.log(statusMessage);
                    alert(statusMessage);
                }
            } catch (err) {
                console.error(err);
                alert(err);
            }
        }

        const confirmReturn = async () => {
            closeModal("confirmReturnModal");
            try {
                loading.value = true
                const response = await axios.post(`/api/v1/burial/paymentreturn/${props.paymentId}`);
                const { success, statusMessage, data } = response.data;
                if (success) {
                    const { emailResponse } = data;
                    emailSuccess.value = emailResponse.success
                    emailMessage.value = emailResponse.statusMessage
                    console.log(emailResponse.statusMessage)
                    loading.value = false
                    window.location.reload()
                } else {
                    loading.value = false
                    console.log(statusMessage);
                    alert(statusMessage);
                }
            } catch (err) {
                loading.value = false
                console.error(err);
                alert(err);
            }
        }

        const markAsPaid = async () => {
            let payload = {
                paymentId: props.paymentId,
                paymentInfo: {
                    paymentMethodId: paymentMethod.value.id,
                    receiptInfo: {
                        method: receiptMethod.value,
                        jamatkhanaId: selectedJamatkhana.value
                    }
                }
            }
            if (paymentMethod.value.name === "Check") {
                payload.paymentInfo.checkNumber = checkNumber.value;
            } else if (paymentMethod.value.name === "Money Order") {
                payload.paymentInfo.moneyOrderNumber = checkNumber.value;
            }

            try {
                const response = await axios.put(`/api/v1/burial/payment`, payload);
                const { success, statusMessage } = response.data;
                if (success) {
                    const { data } = response.data;
                    emailSuccess.value = data.emailConfirmation.success;
                    emailMessage.value = data.emailConfirmation.statusMessage;
                    await getPaymentStatus()
                } else {
                    console.log(statusMessage)
                }
            } catch (err) {
                console.error(err);
            }
        }

        const confirmAdjustment = async () => {
            const payload = {
                paymentId: props.paymentId,
                paymentDetails: {
                    members: members.value,
                    subsidizedTotal: subsidizedTotal.value,
                    donation: donation.value,
                    total: total.value
                },
                subsidizedBy: volunteerInfo.volunteer.id,
                subsidyReason: subsidyReason.value
            }
            try {
                const response = await axios.post("/api/v1/burial/subsidy", payload);
                const {success, statusMessage} = response.data;
                if (!success) {
                    adjustmentModalstatus.value = statusMessage;
                }
                else {
                    router.go()
                }
            } catch (err) {
                console.error(err);
                adjustmentModalstatus.value = "Server error while applying adjustment"
            }
        }

        const disableAdjustmentSubmit = computed(() => {
            let hasChangedMemberRate = false
            members.value.forEach(member => {
                if (member.rate !== member.subsidizedRate) {
                    hasChangedMemberRate = true
                }
            })
            if (subsidyReason.value && hasChangedMemberRate) {
                return false
            }
            return true
        })

        const closeModal = (modalId) => {
            Modal.getInstance(document.getElementById(modalId)).hide()
        }

        const disableMarkAsPaid = computed(() => {
            return !(checkNumber.value && receiptMethod.value && selectedJamatkhana.value && selectedJamatkhana.value !== "N/A");
        })

        const hasPreviousAdjustments = computed(() => {
            return paymentInfo.value && paymentInfo.value.subsidyInfo
        })

        const subsidizedMemberTotal = computed(() => {
            let sum = 0;
            for (const member of members.value) {
                sum += member.subsidizedRate;
            }
            return sum;
        })

        const subsidizedTotal = computed(() => {
            let sum = subsidizedMemberTotal.value
            sum += donation.value;
            return sum;
        })

        getPaymentStatus();

        const volunteerInfo = store.getters.getVolunteerInfo;
        if (volunteerInfo.regions) {
            let jks = [];
            volunteerInfo.regions.forEach(region => {
                jks = [...jks, ...region.jamatkhanas]
            })
            jamatkhanas.value = jks;
        } else if (volunteerInfo.jamatkhanas) {
            jamatkhanas.value = volunteerInfo.jamatkhanas;
            if (volunteerInfo.jamatkhanas.length === 1) {
                selectedJamatkhana.value = jamatkhanas.value[0].id;
            }
        }

        const adjustMemberData = () => {
            members.value = members.value.map(member => {
                return {
                    ...member,
                    subsidizedRate: member.rate
                }
            })
            if (hasPreviousAdjustments.value) {
                members.value = members.value.map(member => {
                    const memberIndex = paymentInfo.value.subsidyInfo.members.findIndex(subsidyMember => member.memberId == subsidyMember.memberId)
                    if (memberIndex === -1) {
                        return member
                    } else {
                        return {
                            ...member,
                            originalRate: paymentInfo.value.subsidyInfo.members[memberIndex].originalRate
                        }
                    }
                })
            }
            isAdjustmentModalReady.value = true
        }

        const getOriginalTotal = () => {
            let sum = 0;
            for (const member of members.value) {
                if (member.originalRate) {
                    sum += member.originalRate
                } else {
                    sum += member.rate
                }
            }
            return sum;
        }

        return {
            sendStatusEmail,
            printReceipt,
            displayInUSD,
            getLocalTimestampfromUtcTimeStamp,
            confirmCancel,
            confirmReturn,
            markAsPaid,
            disableMarkAsPaid,
            loading,
            error,
            coverageYear,
            paymentMethod,
            members,
            donation,
            receiptMethod,
            paymentStatus,
            paymentInitiatedAt,
            paymentInitiatedBy,
            paymentInitiatedById,
            paymentReceivedAt,
            paymentReceivedBy,
            paymentReceivedAs,
            total,
            membersTotal,
            isInVolunteerFlow,
            paymentNotFound,
            checkNumber,
            jamatkhanas,
            selectedJamatkhana,
            achInstructions,
            mailingInstructions,
            termsAndConditions,
            familyLocationId,
            familyLocationName,
            emailSuccess,
            emailMessage,
            isEmailButtonClicked,
            emailButtonDisabled,
            closeModal,
            adjustMemberData,
            subsidizedMemberTotal,
            subsidizedTotal,
            isAdjustmentModalReady,
            volunteerInfo,
            confirmAdjustment,
            subsidyReason,
            disableAdjustmentSubmit,
            adjustmentModalstatus,
            hasPreviousAdjustments,
            getOriginalTotal,
            paymentInfo
        }
    }
}
</script>
<style scoped>
h5 {
    overflow-wrap: break-word;
}
.buttonOption {
    margin: 0px 10px;
}
.modalButton {
    display: flex;
    justify-content: center;
    padding-top: 16px;
}
.modalButtonRight {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
}
.paymentMessageContainer {
    padding-top: 24px;
}
.paymentDetail {
    padding-bottom: 16px;
}
.paymentInfo {
    padding-top: 16px;
}
.paymentStatusBody {
    padding-top: 20px;
}
.pageOptions {
    padding-top: 16px;
    display: flex;
    justify-content: center;
}
.radioLabel {
    padding: 0px 20px 0px 5px;
}
.volunteerPaymentContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 8px;
}
.volunteerInputItem {
    padding-bottom: 10px;
}
</style>
