<template>
  <div>
    <Bar 
        :data="formattedChartData"
        :options="chartOptions" 
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Bar Chart",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      }),
    },
  },
  computed: {
    formattedChartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
    isDataReady() {
      return this.labels.length > 0 && this.datasets.length > 0;
    },
  },
};
</script>