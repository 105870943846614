<template>
<div>
    <NavigationBar />
    <h2> Volunteer Portal </h2>
    <hr>
    <div class="card">
        <div class="card-header bg-primary text-white bg-gradient">
            <h4 class="card-title"><i class="bi bi-search"></i> Search</h4>
        </div>
        <div class="card-body">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action"> <a href="/search" class="text-decoration-none"> Member and Payment Search </a></li>
            </ul>
        </div>
    </div>
    <br>
    <div class="card" v-if="this.currentVolunteer.resources.some(permission => permission.id === 4) || this.currentVolunteer.resources.some(permission => permission.id === 3)">
        <div class="card-header bg-primary text-white bg-gradient">
            <h4 class="card-title"><i class="bi bi-file-bar-graph"></i> Reports</h4>
        </div>
        <div class="card-body">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 3)"> <a href="/collectionsreport" class="text-decoration-none"> Collections </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 3)"> <a href="/collectionscomparisonreport" class="text-decoration-none"> Collections Comparison </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 3)"> <a href="/donationsreport" class="text-decoration-none"> Donations </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 4)"> <a href="/pendingpayments" class="text-decoration-none"> Members with Pending Payments </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 8)"> <a href="/processingpaymentsreport" class="text-decoration-none"> Members with Payments Processing </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 10)"> <a href="/batchesreport" class="text-decoration-none"> Batches </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 12)"> <a href="/unregisteredmembersreport" class="text-decoration-none"> Unregistered Members </a></li>
            </ul>
        </div>
    </div>
    <br>
    <div class="card" v-if="this.currentVolunteer.resources.some(permission => permission.id === 2)">
        <div class="card-header bg-primary text-white bg-gradient">
            <h4 class="card-title"><i class="bi bi-people-fill"></i> User Managment</h4>
        </div>
        <div class="card-body">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action"> <a href="/volunteer/management" class="text-decoration-none"> Volunteer Management </a></li>
                <li class="list-group-item list-group-item-action"> <a href="/volunteer/search" class="text-decoration-none"> Volunteer Search and Permissions Grant </a></li>
            </ul>
        </div>
    </div>
    <br>
    <div class="card" v-if="this.currentVolunteer.resources.some(permission => permission.id === 5) || this.currentVolunteer.resources.some(permission => permission.id === 6)">
        <div class="card-header bg-primary text-white bg-gradient">
            <h4 class="card-title"><i class="bi bi-envelope-paper"></i> Manage Campaign</h4>
        </div>
        <div class="card-body">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 5)"> <a href="/setprices" class="text-decoration-none"> Edit Pricing </a></li>
                <li class="list-group-item list-group-item-action" v-if="this.currentVolunteer.resources.some(permission => permission.id === 6)"> <a href="/setterms" class="text-decoration-none"> Set Terms and Mailing Instructions </a></li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import NavigationBar from '../components/NavigationBar.vue';
import {
    useStore
} from 'vuex';
import {
    useRouter
} from 'vue-router';
import {
    ref
} from 'vue';

export default {
    name: "PortalPage",
    components: {
        NavigationBar
    },
    setup() {

        const store = useStore();
        const router = useRouter();
        const currentVolunteer = ref()

        let isInVolunteerFlow = store.getters.getIsInVolunteerFlow;
        if (isInVolunteerFlow === false) {
            router.push("/notfound");
        }

        return {
            currentVolunteer,
        }
    },
    async created() {
        this.currentVolunteer = this.$store.getters.getVolunteerInfo;
        if (!this.currentVolunteer.resources.some(permission => permission.id === 1)) {
            this.$router.push("/search");
        }
    },
}
</script>

<style scoped>
h3 {
    padding: 12px;
}

h2 {
    text-align: center;
    padding: 10px;
}
</style>
